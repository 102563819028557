import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';

import Timer from '../../Timer';
import { SongCard } from '../../SongCard/SongCard';

import s from '../BattleList.module.scss';
import Reveal, { Fade, Slide } from 'react-awesome-reveal';

import { keyframes } from "@emotion/react";
import MusicBattleItem from '../../BattleItem';
import Comments from '../../VideoPlayer/Comments';
import mockImage from '../../../assets/mock/battle.jpg'
import { battleAPI } from '../../../api/api';
import vs from '../../../assets/musicbet/VSIcon_1.mp4'
import MusicHeader from '../../MusicHeader';
import { toast } from 'react-toastify';
import gif from '../../../assets/musicbet/VSIcon_1.gif'

const customAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;


const BattleItem = () => {



    const [videoVisible, setVideoVisible] = useState(true)
    const [timerVisible, setTimerVisible] = useState(false)

    useEffect(() => {
        // Скрываем GIF через 2.88 секунды
        const timer = setTimeout(() => {
          setVideoVisible(false);
        }, 3500); // 2.88 секунды = 2880 миллисекунд

        setTimerVisible(true)

        // Очищаем таймер при размонтировании компонента
        return () => clearTimeout(timer);
      }, []);

    const isDesktop = useMediaQuery({
        query: "(min-width: 1200px)",
    });

    const [searchParams] = useSearchParams();
    const [battle, setBattle] = useState<any>()
    let battleId: any = searchParams.get('id');
    let display: any = searchParams.get('display')

    useEffect(() => {
        battleAPI.getBattleById(battleId)
            .then(data => {
                setBattle(data)
            })
            .catch(() => {
                toast.error("Login or Register to see battle")
            })
    }, [])





    if (battle) {

        if (isDesktop) {
            return (
                <>
                    <MusicHeader shareable title={`Battle #${display}`} returnable primaryColor='#fff' />
                    <p className={s.bidsAmount}>$ {battle?.bids_amount} Vol.</p>
                    <div className={s.timer_wrapper}>
                        <Timer date={battle?.ended_at} />
                    </div>
                    <Row justify={'center'} style={{ flexDirection: 'row' }} align={'top'}>
                        <Col md={11}>
                            <Reveal triggerOnce delay={100} keyframes={customAnimation}>
                                <MusicBattleItem
                                    artist={battle?.track1.artists_names}
                                    id={battle.id}
                                    track={battle?.track1}
                                    song={battle?.track1.name}
                                    video_240_url={battle?.track1?.video_240_url}
                                    video_360_url={battle?.track1?.video_360_url}
                                    video_480_url={battle?.track1?.video_480_url}
                                    video_720_url={battle?.track1?.video_720_url}
                                    video_1080_url={battle?.track1?.video_1080_url}
                                    track_id={battle?.track1?.id}
                                    image={battle?.track1.cover} end_date={battle.ended_at} />
                            </Reveal>
                        </Col>
                        <Col md={11}>
                            <Reveal triggerOnce delay={100} keyframes={customAnimation}>
                                <MusicBattleItem
                                    artist={battle?.track2.artists_names}
                                    song={battle?.track2.name}
                                    id={battle.id}
                                    track={battle?.track2}
                                    video_240_url={battle?.track2?.video_240_url}
                                    video_360_url={battle?.track2?.video_360_url}
                                    video_480_url={battle?.track2?.video_480_url}
                                    video_720_url={battle?.track2?.video_720_url}
                                    video_1080_url={battle?.track2?.video_1080_url}
                                    track_id={battle?.track2?.id}
                                    image={battle?.track2.cover} end_date={battle.ended_at} />
                            </Reveal>
                        </Col>
                        <Col span={19}>
                            <div className={s.comments_wrapper}>
                                <Comments videoId={battleId} />
                            </div>
                        </Col>
                    </Row>
                </>
            )
        }

        else {
            return (
                <>
                    <MusicHeader shareable title={`Battle #${display}`} returnable primaryColor='#fff' />
                    <p className={s.bidsAmount}>$ {battle?.bids_amount} Vol.</p>
                    <Row justify={'center'}>
                        <Col span={24}>
                            <Reveal triggerOnce delay={100} keyframes={customAnimation}>
                                <Slide triggerOnce delay={110} direction={'down'}>

                                    <MusicBattleItem
                                        artist={battle?.track1.artists_names}
                                        id={battle.id}
                                        track={battle?.track1}
                                        song={battle?.track1.name}
                                        video_240_url={battle?.track1?.video_240_url}
                                        video_360_url={battle?.track1?.video_360_url}
                                        video_480_url={battle?.track1?.video_480_url}
                                        video_720_url={battle?.track1?.video_720_url}
                                        video_1080_url={battle?.track1?.video_1080_url}
                                        track_id={battle?.track1?.id}
                                        image={battle?.track1.cover} end_date={battle.ended_at} />
                                </Slide>
                            </Reveal>
                        </Col>
                    </Row>
                    <Reveal triggerOnce delay={100} keyframes={customAnimation}>
                        <Row style={{ height: "120px" }} align={'middle'} justify={'center'}>
                            <Col span={24}>
                                {/* <video ref={videoRef} onEnded={() => { setVideoVisible(false); setTimerVisible(true) }} style={!videoVisible ? { display: 'none' } : { display: 'block', margin: "0 auto" }} src={vs} muted  playsInline/> */}
                               <img style={!videoVisible ? { display: 'none' } : { display: 'block', margin: "0 auto" }} src={gif}/>
                                <Fade triggerOnce delay={4000}>
                                    <div style={!timerVisible ? { display: 'none' } : {}} className={s.timer_wrapper}>
                                        {/* <div style={{}} className={s.timer_wrapper}> */}
                                        <Timer date={battle?.ended_at} />
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                    </Reveal>
                    <Row>

                        <Col span={24}>
                            <Reveal triggerOnce delay={100} keyframes={customAnimation}>
                                <Slide triggerOnce direction='up' delay={110}>
                                    <MusicBattleItem
                                        artist={battle?.track2.artists_names}
                                        song={battle?.track2.name}
                                        id={battle.id}
                                        track={battle?.track2}
                                        video_240_url={battle?.track2?.video_240_url}
                                        video_360_url={battle?.track2?.video_360_url}
                                        video_480_url={battle?.track2?.video_480_url}
                                        video_720_url={battle?.track2?.video_720_url}
                                        video_1080_url={battle?.track2?.video_1080_url}
                                        track_id={battle?.track2?.id}
                                        image={battle?.track2.cover} end_date={battle.ended_at} />
                                </Slide>
                            </Reveal>
                        </Col>
                    </Row>
                    <Row justify={'center'}>
                        <Fade triggerOnce delay={200}>

                            <Col span={24}>
                                <div className={s.comments_wrapper}>
                                    <Comments videoId={battleId} />
                                </div>
                            </Col>
                        </Fade>
                    </Row>

                </>
            )
        }
    }


    return <></>
};

export { BattleItem };
