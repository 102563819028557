import React, { useEffect, useState } from "react";
import { ProfileLayout } from "../../../components/ProfileLayout/ProfileLayout";
import styles from './Withdraw.module.scss';
import { Button, Checkbox, Input, Popover } from "antd";

const Withdraw = () => {
  const [crypto, setCrypto] = useState<string | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const [data, setData] = useState<any>()
  const [isDisabled, setDisabled] = useState(true);
  useEffect(() => {
    // Проверяем, если одно из полей пустое (null или пустая строка)
    if (crypto && amount) {
      setDisabled(false); // Включаем кнопку, если оба поля заполнены
    } else {
      setDisabled(true); // Выключаем кнопку, если хотя бы одно поле пустое
    }
  }, [crypto, amount]);

  const content = (
    <div className={styles.popover_content}>
      <p>Check the correctness of this data:</p>
      <p>Wallet: <strong>{crypto}</strong></p>
      <p>Amount: <strong>{amount}</strong></p>
      <Button onClick={() => setData({wallet:crypto, amount:amount})} className={styles.withdraw_pop}>Withdraw</Button>
    </div>
  );

  return (
    <ProfileLayout>
      <div className={styles.buy_tokens_header}>
        <h2 className={styles.buy_tokens_header__text}>WITHDRAW</h2>
      </div>

      <div className={styles.input_items}>
        <Input
          onChange={(e) => setCrypto(e.target.value)}
          placeholder="Enter your crypto wallet address"
          className={styles.input}
        />
        <Input
          onChange={(e) => setAmount(parseFloat(e.target.value) || null)}
          type="number"
          step={0.01}
          placeholder="0.00 TKN"
          className={styles.input}
        />
        <Popover content={content} trigger="click">
          <Button className={styles.withdraw} disabled={isDisabled}>
            Withdraw
          </Button>
        </Popover>
      </div>
    </ProfileLayout>
  );
};

export default Withdraw;