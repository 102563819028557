import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { Button, ConfigProvider, Drawer } from "antd";

import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import { toggleVisibleMenu, toggleVisibleMenuAll } from "./store/mainMenuSlice";
import { Navigate } from "./components/Navigate/Navigate";
import { Layout } from "./components/Layout/Layout";
import { NFTBattles } from "./pages/NFTBattles/NFTBattles";
import { Winners } from "./pages/Winners/Winners";
import { Support } from "./pages/Support/Support";
import { HowToPlayAndWin } from "./pages/HowToPlayAndWin/HowToPlayAndWin";
import { Battles } from "./components/Profile/Battles/Battles";
import { ProfileSettings } from "./components/ProfileSettings/ProfileSettings";
import { ButtonsPage } from "./pages/NFTBattles/buttonsPage/ButtonsPage";
import { ShortNavigate } from "./components/Navigate/ShortNavigate";
import { SupportFAQ } from "./components/SupportFAQ/SupportFAQ";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import { setVideoData, setVoteData } from "./store/playerSlice";
import VideoPlayer from "./components/VideoPlayer";

import { Playlist } from "./components/Profile/Playlist/Playlist";
import { GameRules } from "./pages/GameRules/GameRules";
import { Terms } from "./pages/Terms/Terms";
import { Privacy } from "./pages/Privacy/Privacy";
import Report from "./pages/Report/Report";
import BuyTokens from "./components/BuyTokens";
import ContactUs from "./components/ContactUs";

import styled from "./App.module.scss";

import Battle from "./pages/NFTBattles/Battle";
import ProfileWallet from "./components/ProfileWallet";
import { profileAPI } from "./api/api";

import { MantineProvider } from "@mantine/core";
import BattleArchive from "./pages/BattleArchive/BattleArchive";
import BattleRandomPage from "./pages/BattleRandom";
import TournamentGenres from "./pages/TournamentGenres";
import BattleHistory from "./pages/Profile/BattleHistory";
import Withdraw from "./pages/Profile/Withdraw";
import Genres from "./pages/Genres";
import MusicTournament from "./pages/MusicTournament";
import MarketPlace from "./pages/Marketplace";
import Bonus from "./pages/Profile/Bonus";
import { Contacts } from "./pages/Contacts/Contacts";
import { ReactComponent as Balance } from './assets/svg/account_balance.svg'
import { sprintf } from 'sprintf-js'
import Song from "./pages/Marketplace/Song";
import { BattleItem } from "./components/BattleList/BattleItem.tsx/BattleItem";
import { NotificationT } from "./components/ToastifyNot/NotificationToastify";


const Home = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1200px)",
    });

    const navigate = useNavigate();

    if (localStorage.getItem("isAuth")) {
        navigate("/genres");
    } else if (localStorage.getItem("isAuth") && isDesktop) {
        navigate("/login");
    } else {
        navigate("/genres");

    }

    return null;
};

const App = () => {
    const [isVisibleDesktopMenu, setIsVisibleDesktopMenu] = useState(true);
    const { balance } = useAppSelector((state) => state.player);

    const [isAuth, setIsAuth] = useState(true)
    const [isVisibleShortDesktopMenu, setIsVisibleShortDesktopMenu] =
        useState(false);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate()
    const { pathname } = location;

    useEffect(() => {
        const handleStorageChange = () => {
            if (localStorage.getItem('isAuth')) {
                setIsAuth(true);
            } else {
                setIsAuth(false);
            }
        };

        // Проверка авторизации при монтировании компонента
        handleStorageChange();

        window.addEventListener('storage', handleStorageChange);

        // Очистка обработчика при размонтировании компонента
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []); // Пустой массив зависимостей, чтобы эффект запускался один раз при монтировании

    const idVideo = useAppSelector((state) => state.player.videoId);

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: `900`px)",
    });

    const isDesktop = useMediaQuery({
        query: "(min-width: 1200px)",
    });

    useEffect(() => {
        dispatch(toggleVisibleMenuAll(isDesktopOrLaptop));
    }, [dispatch, isDesktopOrLaptop]);

    useEffect(() => {
        dispatch(toggleVisibleMenu(location.pathname));
    }, [dispatch, location]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {

    })

    useEffect(() => {
        if (!isDesktop) {
            setIsVisibleDesktopMenu(false);
        }
        if (
            location.pathname === "/login" ||
            location.pathname === "/" ||
            location.pathname === "/#" ||
            location.pathname === ""
        ) {
            setIsVisibleDesktopMenu(false);
            setIsVisibleShortDesktopMenu(false);
            return;

        } else if (!isDesktop) {
            setIsVisibleShortDesktopMenu(false);
            setIsVisibleDesktopMenu(false);
            return;
        } else {
            setIsVisibleShortDesktopMenu(false);
            setIsVisibleDesktopMenu(true);
        }
    }, [isDesktop, location.pathname]);
    const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);

    const handleClose = () => {
        dispatch(
            setVoteData({
                vote_title: "",
                vote: () => null,
                vote_singer: "",
                vote_video: { id: 0 },
                setISVoted: () => null,
                vote_id: 0,
                pick: null,
                setPick: () => null
            })
        );
        dispatch(
            setVideoData({
                id: 0,
                url: "",
                image: "",
                title: "",
                genre: "",
                artists_names: [""],
                categories_names: [""],
            })
        )
    }

    useEffect(() => {
        profileAPI
            .getProfile()
            .then((res) => { })
        // .catch(() => navigate("/login"))
    }, [])

    const [isCookieVisible, setCookieVisible] = useState<any>("true")

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#fff',
                    },
                    Select: {
                        colorPrimary: '#C8102E',
                    },
                    Slider: {
                        colorPrimary: 'gray',
                    },
                    Switch: {
                        colorTextQuaternary: "#1D428A",
                        colorTextTertiary: "#1D428A",
                    },
                },
                // Устанавливаем цвета токенов
                token: {
                    colorPrimary: '#fff',
                    colorBgContainer: '#fff',
                },
            }}
        >
            <NotificationT/>
            <div style={localStorage.getItem('cookie_agreed') === "true" ? { display: 'none' } : {}} className={styled.cookie}>
                <h3>We value your privacy</h3>
                <div className={styled.cookie_info}>
                    <p>We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.  Cookie Policy</p>
                    <Button onClick={() => { setCookieVisible('false'); localStorage.setItem('cookie_agreed', 'true') }} className={styled.accept_btn}>Accept</Button>
                </div>
            </div>
            <div
                className={styled.container}
                style={location.pathname.includes('login') ? { padding: "0 300px" } : {}}>
                {isVisibleDesktopMenu && <Navigate />}
                <Layout>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        {/*  <Route path='explore' element={<Explore />} /> */}

                        {/* <Route path='nft-battles' element={<NFTBattles />} /> */}

                        {/* battles pages start */}
                        <Route path="genres" element={<Genres />} />
                        <Route path="tournament" element={<MusicTournament />} />
                        <Route path='battles/tournament' element={<ButtonsPage />} />
                        <Route path="random-battle" element={<BattleRandomPage />} />
                        <Route path='battle/all-battles' element={<NFTBattles />} />
                        <Route path='battle' element={<BattleItem />} />
                        <Route path='battle-results' element={<BattleArchive />} />
                        <Route path='tournament-genres' element={<TournamentGenres />} />
                        {/* battles pages finish */}
                        {/* <Route path='buy-nft' element={<BuyNFT />} /> */}
                        <Route path='hall-of-fame' element={<Winners />} />
                        <Route path='gameplay' element={<GameRules />} />
                        <Route path='marketplace' element={<MarketPlace />} />
                        <Route path='marketplace/:id' element={<Song />} />
                        {/* profile */}
                        {/* <Route path='profile' element={<NFTSong />} /> */}
                        <Route path='profile' element={<ProfileSettings />} />
                        <Route path='profile/playlists' element={<Playlist />} />
                        <Route path='profile/battles' element={<Battles />} />
                        <Route path='/profile/buy-tokens' element={<BuyTokens />} />

                        <Route path='profile/withdraw' element={<Withdraw />} />
                        <Route path='profile/battle-history' element={<BattleHistory />} />
                        <Route path='profile/help' element={<ContactUs />} />
                        <Route path='profile/my-music' element={<ProfileWallet />} />
                        <Route path="/profile/bonus" element={<Bonus />} />
                        {/* profile-end */}

                        {/* WInners */}
                        {/* WInners end */}
                        {/* <Route path='your-playlist' element={<YourPlaylist />} /> */}
                        <Route path='profile/playlists' element={<Playlist />} />
                        <Route path='support' element={<Support />}>
                            <Route path='/support/:idCategory/FAQ' element={<SupportFAQ />} />
                        </Route>
                        <Route path='contacts' element={<Contacts />} />
                        <Route path='login' element={<HowToPlayAndWin />} />
                        <Route path='reset-password' element={<ResetPassword />} />

                        {/* footer */}
                        <Route path='terms-n-conditions' element={<Terms />} />
                        <Route path='privacy' element={<Privacy />} />
                        <Route path='cookie' element={<Report />} />

                    </Routes>
                </Layout>
                <Button style={!localStorage.getItem('isAuth') ? { display: 'none' } : {}} className={styled.button_wallet} type={"link"} onClick={() => navigate('/profile/buy-tokens')}>
                    {
                        !isDesktop ? <></> :
                            <div className={styled.button_wrapper}>
                                <Balance style={{ fill: '#fff', width: "30px", height: 'auto' }} />
                                <span style={{ paddingLeft: 10 }}>
                                    {sprintf('%.2f', parseFloat(balance))}</span>
                            </div>
                    }
                </Button>

                <Drawer
                    onClose={handleClose}
                    placement={"bottom"}
                    open={!!idVideo}
                    height={"100%"}
                    destroyOnClose
                    styles={{
                        content: { height: "100%" },
                        body: { padding: "20px 5px" },
                        header: { display: "flex", justifyContent: "flex-end" },
                    }}
                >
                    <MantineProvider>
                        <VideoPlayer />
                    </MantineProvider>
                </Drawer>
            </div>
        </ConfigProvider>
    );
};

export default App;
