import React from "react";
import styles from './BattleDivider.module.scss'
import thunder from '../../assets/musicbet/bolt.svg'

interface DividerProps {
 isDayX?: boolean
 primaryColor?: string,
 secondaryColor?: string
}

  const  BattleDivider = (props: DividerProps) => {

   const mockColor = `linear-gradient(90deg, ${props.primaryColor} 0%, ${props.secondaryColor} 100%)`;

 return(
  <div style={{background: mockColor}} className={styles.wrapper}>
   <img src={thunder} alt="bolt" className={styles.thunder}/>
   <h2 className={styles.title}>X DAY FINAL</h2>
  </div>
 )
}

export default BattleDivider;
