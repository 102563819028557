import React from "react";

import styled from './MusicHeader.module.scss'
import {ReactComponent as Returnable} from '../../assets/musicbet/returnable.svg'
import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ReactClipboard from "react-clipboardjs-copy";
import { toast } from "react-toastify";
import { NotificationT } from "../ToastifyNot/NotificationToastify";
import {ReactComponent as Share} from '../../assets/musicbet/Undo button.svg'
import useClipboard from "react-use-clipboard";

interface HeaderProps {
 title: string,
 primaryColor: string
 color?: string,
 fontSize?: number
 returnable?: boolean
 shareable?: boolean
}


const MusicHeader = (props: HeaderProps) => {
 const [isCopied, setCopied] = useClipboard(window.location.href);

 const isDesktop = useMediaQuery({
  query: "(min-width: 1200px)",
 });



 const navigate = useNavigate()
 return(
  <>
  {/* <NotificationT/> */}
  <div style={{background: `linear-gradient(90deg, #000,${props.primaryColor},#000`}} className={styled.music_header}>
  {!isDesktop && props.shareable ?
   <Button onClick={setCopied} type="link" className={styled.share}><Share/></Button>
   :
   <></>}
   <p className={styled.title} style={{color: props.color, fontSize: props.fontSize}}>{props.title}</p>
   {
    !isDesktop  && props.returnable? <Button onClick={() => navigate(-1)} type="link" className={styled.return}><Returnable/></Button> : <></>
   }

  </div>
   </>
 )
}

export default MusicHeader;
