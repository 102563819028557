/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import MusicHeader from "../../components/MusicHeader";
import styles from './MusicTournament.module.scss'

import { Col, Row } from "antd";
import BattleItem from "../../components/BattleItem";
import BattleDivider from "../../components/BattleDivider";
import MusicBattleItem from "../../components/BattleItem";

import mockImage from '../../assets/mock/battle.jpg'
import { genresAPI, musicAPI } from "../../api/api";
import { useSearchParams } from "react-router-dom";

const MusicTournament = () => {

  const [genres, setGenres] = useState<any>()
  const [tournament, setTournament] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [genreInfo, setGenreInfo] = useState<any>()
  const [searchParams] = useSearchParams();
  const colorId = searchParams.get('id')

  console.log(tournament)

  useEffect(() => {
    genresAPI
      .getGenres()
      .then((res) => {
        setGenres(res)
        console.log(res)
        const genre = res.find((item) => {
          return item.id.toString() === colorId
        })
        setGenreInfo(genre)
      })
      .then(() => {
        musicAPI.getTournamentsById(colorId).then((res) => setTournament(res?.data[0])).then(() => setLoading(false))
      })
  }, [colorId])


  if (loading) {
    return null;
  }

  return (
    <div>
      <MusicHeader returnable title={`${genreInfo.name} Tournament`} primaryColor={genreInfo?.data?.primary_color} color="#fff" />
      <div className={styles.wrapper}>
        <Row justify={'center'}>
          <Col span={11}>
            <MusicBattleItem
              display_id={1}
              id={tournament?.battles[0]?.id}
              image={tournament?.battles[0]?.data?.cover}
              end_date={tournament?.battles[0]?.ended_at}
              primaryColor={genreInfo.data?.primary_color}
              secondaryColor={genreInfo.data?.secondary_color}
            />

          </Col>
          <Col span={11}>
            <MusicBattleItem
              display_id={2}
              id={tournament?.battles[1]?.id}
              image={tournament?.battles[1]?.data?.cover}
              end_date={tournament?.battles[1]?.ended_at}
              primaryColor={genreInfo.data?.primary_color}
              secondaryColor={genreInfo.data?.secondary_color}

            />

          </Col>
        </Row>
        <Row justify={'center'}>
          <Col span={11}>
            <MusicBattleItem
              display_id={5}
              id={tournament?.battles[4]?.id}
              image={tournament?.battles[4]?.data?.cover}
              end_date={tournament?.battles[4]?.ended_at}
              primaryColor={genreInfo.data?.primary_color}
              secondaryColor={genreInfo.data?.secondary_color}
            />
          </Col>
        </Row>
        <Row justify={'center'}>
          <BattleDivider primaryColor={genreInfo.data?.primary_color}
            secondaryColor={genreInfo.data?.secondary_color} />
        </Row>
        <Row justify={'center'}>
          <Col span={11}>
            <MusicBattleItem
              display_id={6}
              id={tournament?.battles[6]?.id}
              image={tournament?.battles[6]?.data?.cover}
              end_date={tournament?.battles[6]?.ended_at}
              primaryColor={genreInfo.data?.primary_color}
              secondaryColor={genreInfo.data?.secondary_color}
            />
          </Col>

        </Row>
        <Row justify={'center'}>
          <Col span={11}>
            <MusicBattleItem
              display_id={3}
              id={tournament?.battles[2]?.id}
              image={tournament?.battles[2]?.data?.cover}
              end_date={tournament?.battles[2]?.ended_at}
              primaryColor={genreInfo.data?.primary_color}
              secondaryColor={genreInfo.data?.secondary_color}

            />
          </Col>
          <Col span={11}>
            <MusicBattleItem
              display_id={4}
              id={tournament?.battles[3]?.id}
              image={tournament?.battles[3]?.data?.cover}
              end_date={tournament?.battles[3]?.ended_at}
              primaryColor={genreInfo.data?.primary_color}
              secondaryColor={genreInfo.data?.secondary_color}


            />
          </Col>

        </Row>
      </div>

    </div>
  )
}

export default MusicTournament;
