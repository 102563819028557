import cross from '../../../assets/svg/mobile-icons/Path.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { closeLoginModalMenu } from '../../../store/loginModalSlice';
import { Registration } from '../Registration';
import { SignIn } from '../SignIn';
import { ReactComponent as Cross } from '../../../assets/svg/mobile-icons/Path.svg';
import { ReactComponent as CrossMobile } from '../../../assets/svg/mobile-icons/Pathtwo.svg';

import styled from './LoginModal.module.scss';
import { useMediaQuery } from 'react-responsive';
import { ForgottenPassword } from "../ForgottenPassword";
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const LoginModal = () => {
  const dispatch = useAppDispatch();
  const loginScene = useAppSelector((state) => state.modalLogin.loginScene);

  const navigate = useNavigate()

  const isMobile = useMediaQuery({
    query: '(max-width: 430px)',
  });

  function closeMobileMenu() {
    dispatch(closeLoginModalMenu(null));
  }

  return (
    <div className={styled.container}>

      {!isMobile &&
        <Cross className={styled.cross} onClick={() => closeMobileMenu()} />
      }
      {isMobile &&
        <CrossMobile className={styled.cross} onClick={() => closeMobileMenu()} />
      }
      {loginScene === 'signIn' && <SignIn />}
      {loginScene === 'register' && <Registration />}
      {loginScene === 'forgottenPassword' && <ForgottenPassword />}
      <div className={styled.footer_btn}>
        <Button onClick={() => navigate('/terms-n-conditions')}>
          Terms & Conditions
        </Button>
        <Button onClick={() => navigate('/privacy')}>
          Privacy Policy
        </Button>
        <Button onClick={() => navigate('/cookie')}>
          Cookie Policy
        </Button>
      </div>
      <h2 className={styled.footer_text}>© 2024 Music.bet</h2>
    </div>
  );
};

export { LoginModal };
