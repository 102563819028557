import styled from './Battle.module.scss'


import MusicHeader from "../../components/MusicHeader";
import BattleResultsItem from "../../components/BattleResultsItem";

import mockCover from '../../assets/mock/Battle_mokup 2.png'
import { List } from 'antd';
import { PaginationAlign, PaginationPosition } from 'antd/es/pagination/Pagination';
import { useEffect, useState } from 'react';
import { battleAPI, genresAPI, musicAPI } from '../../api/api';

const BattleArchive = ({ onlyResults }: { onlyResults?: boolean }) => {
  const [results, setResults] = useState<any>()
  const [genres, setGenres] = useState<any>()
  const [isLoading, setLoading] = useState(true)

  const [battle, setBattle] = useState<any>()

  const [genreInfo, setGenreInfo] = useState<any>()
  const [data, setData] = useState<any>()

  const colorId = 3

  useEffect(() => {
    musicAPI.getBattleResults().then((res) => setData(res))
  }, [])

  useEffect(() => {
    genresAPI
      .getGenres()
      .then((res) => {
        setGenres(res)
        console.log(res)
        const genre = res.find((item) => {
          return item.id.toString() === colorId
        })
        setGenreInfo(genre)
      })
  }, [colorId])


  useEffect(() => {
    battleAPI
      .getResults()
      .then((res) => {
        setResults(res.data)
        console.log(res)
      })
      .then(() => {
        setLoading(false)
      })
  }, [])

  // console.log(results)
  // console.log(genreInfo)

  const getGenreInfo = (id) => {
    return genresAPI
      .getGenres()
      .then((res) => {
        const genre = res.find((item) => item.id.toString() === id);
        return genre ? genre.name : 'Genre not found';
      })
      .catch((error) => {
        console.error('Error fetching genres:', error);
        return 'Error fetching genre';
      });
  };




  // const mock = [
  //   { id: 1, tournament: 'Pop', end_date: '22.11.2024', winner: "Doja Cat", prizePull: 2000, winningPlayers: 100, cover: mockCover },
  //   { id: 2, tournament: 'Pop', end_date: '22.11.2024', winner: "Doja Cat", prizePull: 2000, winningPlayers: 100, cover: mockCover },
  //   { id: 3, tournament: 'Pop', end_date: '22.11.2024', winner: "Doja Cat", prizePull: 2000, winningPlayers: 100, cover: mockCover },
  //   { id: 4, tournament: 'Pop', end_date: '22.11.2024', winner: "Doja Cat", prizePull: 2000, winningPlayers: 100, cover: mockCover },
  //   { id: 5, tournament: 'Pop', end_date: '22.11.2024', winner: "Doja Cat", prizePull: 2000, winningPlayers: 100, cover: mockCover },

  // ]
  interface NormalizedBattleData {
    id: number;
    tournament: any; // Genre of the winning track
    end_date: string; // Formatted end date
    winner: string; // Name of the winning track
    prizePull: number; // Prize amount
    winningPlayers: number; // Number of players
    cover: string; // Cover image URL
  }

  const normalizeBattleData = (data: any): NormalizedBattleData => {
    const winnerTrackId = data?.data?.winner_track_id; // Get winner track ID
    const winningTrack = data?.track1?.id === winnerTrackId ? data?.track1 : data?.track2; // Determine winning track

    return {
      id: data?.id,
      tournament: winningTrack?.categories_names[0].name, // Extract genre
      end_date: new Date(data?.ended_at).toLocaleDateString(), // Format end date
      winner: winningTrack?.name, // Winner's name
      prizePull: parseFloat(data?.data?.prize_fund), // Prize fund
      winningPlayers: data?.data?.winners_count, // Number of participants
      cover: data?.data?.cover // Cover image
    };
  };
  console.log(data)
  const formattedData: NormalizedBattleData[] = data?.data?.map(item => normalizeBattleData(item));

  console.log(formattedData)

  if(isLoading) {
    return null
  }
  return (
    <>
      <MusicHeader title="BATTLE RESULTS" primaryColor="#F56040" color="#fff" />
      <div className={styled.content}>
      <List
  pagination={{ position: 'bottom', align: 'center' }}
  dataSource={formattedData}
  renderItem={(item, index) => (
    <BattleResultsItem
      index={index}
      genre={item.tournament}  // Жанр
      ended_at={item.end_date}  // Дата окончания
      winner={item.winner}  // Победитель
      prize={item.prizePull}  // Призовой фонд
      cover={item.cover}  // Обложка
      players={item.winningPlayers}  // Участники
      battle={item.id}  // ID битвы
    />
  )}
/>
      </div>
    </>
  )
}

export default BattleArchive;
