import { Col, Row } from 'antd';

import styled from './Terms.module.scss';
import MusicHeader from '../../components/MusicHeader';

const Terms = () => {

  return (
    <div className={styled.wrapper}>
      <MusicHeader title='Terms & Conditions' primaryColor='#0E664B' color='#fff' />

      <Row className={styled.container}>
        <Col /* className={styled.subHeader} */ span={24}>
          <p className={styled.text}>
            Before the Player's transaction can be completed, the Player must read and agree to these terms and conditions, hereinafter referred to as the "Agreement." By applying for access and/or services from this website, the Player agrees to these terms and conditions and undertakes to comply with them. This Agreement may be amended at any time. Amendments take effect upon publication on the website without notice to each Player.
          </p>
          <h2 className={styled.subHeader}>
            PREAMBLE
          </h2>
          <p className={styled.text}>
            After purchasing tokens, the Player will receive tokens in their wallet in their account on the Music.bet website. The contract between the Player and the website is considered concluded once the Player receives the purchased tokens. <br />
            All transaction-related inquiries will be reviewed within two business days. <br />
            Registration on the website is strictly prohibited for persons who have not reached the age of majority in their country/jurisdiction, but at a minimum, for persons under 18 years of age.
          </p>

          <h2 className={styled.subHeader}>DEFINITIONS</h2>
          <p className={styled.text}>
            "Account" means the Player's or user's account with a username and password for the website. <br />
            "Site" means the Music.bet website for which the Player purchases game tokens to access the website and its materials, as well as to receive the benefits of the account and the site's services. <br />
            "Player" means the user of the site's services and the holder of a valid username and password for the website. <br />
            "MBT" Music Bet Tokens refers to the game's tokens on the Music.bet website that allow players on the website to make predictions and vote for the winner in fantasy battles on the Music.bet platform, aiming to win prizes described in the game rules on the Gameplay page. <br />
            "Access Right" means the combination of a unique username and password used to access the website. The Access Right is a license to use the website.

          </p>

          <h2 className={styled.subHeader}>DESCRIPTION OF SERVICES</h2>
          <p className={styled.text}>Music.bet provides access rights to the website and its materials, organizing virtual fantasy battles between music video clips where players make predictions and vote for the winner to win game tokens.</p>

          <h2 className={styled.subHeader}>PAYMENT</h2>
          <p className={styled.text}>Payments are processed via the TRC20 network using the USDT (Tether) cryptocurrency. <br />
            TRC-20 is a standard for smart contracts on the TRON blockchain. <br />
            The Player will be informed at the time of purchasing game tokens about all applicable fees. <br />
            To track the USDT TRC20 transaction, use blockchain explorers like Tronscan (https://tronscan.io/?roistat_visit=201954#/) to confirm (verify) payments in case of any disputes or questions. <br />
            Inquiries should be directed to Music.bet support directly from the Player's account on Music.bet.
          </p>

          <h2 className={styled.subHeader}>CANCELLATION</h2>
          <p className={styled.text}>The Player's account may be terminated at any time, with cause, by either music.bet or the Player, with notification to the other party via a message from their account on the Music.bet website. The Player is responsible for all charges incurred up to the termination date.</p>

          <h2 className={styled.subHeader}>REFUNDS</h2>
          <p className={styled.text}>A request for a refund for token purchases can be directed to customer support from the Player's account on the Music.bet website. <br />
            Refunds or compensation will not be issued for used MBT game tokens. Music.bet reserves the right to issue refunds or credits at its discretion. A refund decision does not imply an obligation to provide additional future refunds. If cryptocurrency funds are refunded, the refund will be credited solely through the payment method used in the original transaction. Music.bet does not issue refunds in cash, checks, or through other payment mechanisms.
          </p>

          <h2 className={styled.subHeader}>TRANSACTION DISPUTES / PAYMENT RETURNS</h2>
          <p className={styled.text}>We carefully review all payment disputes to ensure that no party has been incorrectly charged. We also reserve the right to deny future purchases to accounts with unjustified payment returns, depending on the circumstances. Fraud claims are taken seriously and may result in notifying relevant services used by the Player to protect the Player and prevent future fraudulent payments.</p>

          <h2 className={styled.subHeader}>AUTHORIZATION FOR USE</h2>
          <p className={styled.text}>Players are authorized to access the services or materials located on this website through a single Access Right. This Access Right is provided for the sole use of one Player. All accounts are intended for personal use only and may not be used for commercial purposes or by third parties.</p>

          <h2 className={styled.subHeader}>COPYRIGHT</h2>
          <p className={styled.text}>The copyrights for any video clips, music tracks, photos, or other content hosted on the Music.bet website belong to their respective rights holders. <br />
            It is strictly prohibited to copy and use any media materials from the Music.bet website without their and Music.bet organizers' consent.
          </p>

          <h2 className={styled.subHeader}>NOTICES</h2>
          <p className={styled.text}>Notices from the website to Players may be sent through electronic messages via the website, general postings on the website, or email. Notices from Players may be sent via email unless otherwise specified in the Agreement. All inquiries, complaints, or notices regarding the website must be directed to Music.bet. <br />
            Inquiries and contact information: All questions regarding these terms and conditions should be directed to Music.bet at support@music.bet.
          </p>

          <h2 className={styled.subHeader}>DISCLAIMER</h2>
          <p className={styled.text}>The website makes no representations or warranties regarding the legality of the use of materials or content or the authorization for their use in all countries, states, provinces, counties, or other jurisdictions. If a Player chooses to access the website, they do so at their own risk and subject to the laws of their jurisdiction. The Player is responsible for complying with all applicable laws.</p>


          <h2 className={styled.subHeader}>MODIFICATIONS TO THESE TERMS</h2>
          <p className={styled.text}>We reserve the right, at our discretion, to modify these Terms from time to time. If we make changes, we will notify you by providing notice through the Services or updating the "Last Updated" date at the top of these Terms. Unless otherwise stated in our notice, all modifications are effective immediately, and your continued use of the Site and Services after notification constitutes your acceptance of the changes. If you do not agree with the amended Terms, you should stop using the Site and Services.</p>
          <p className={styled.text}>THE COMPANY WILL NOT BE LIABLE AND SHALL NOT BE RESPONSIBLE FOR ANY LOSSES, DAMAGES, OR CLAIMS ARISING FROM: (I) USER ERRORS SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED WALLET ADDRESSES; (II) SERVER FAILURES OR DATA LOSS; (III) CORRUPTED CRYPTOCURRENCY WALLET FILES; (IV) UNAUTHORIZED ACCESS TO SERVICES; OR (V) THIRD-PARTY ACTIVITIES, INCLUDING BUT NOT LIMITED TO VIRUSES, PHISHING, BRUTE FORCE ATTACKS, OR OTHER MEANS OF ATTACK ON ANY BLOCKCHAIN NETWORK UNDERLYING THE SERVICES.</p>

          <h2 className={styled.subHeader}>GENERAL INFORMATION</h2>
          <p className={styled.text}>Privacy Policy. Please refer to our privacy policy, which is incorporated by reference and available at Privacy Policy, for information on how we collect, use, share, and otherwise process information about you. <br />
            Consent to Electronic Notifications. You agree to receive all communications, agreements, documents, receipts, notices, and disclosures electronically (collectively, "Communications") that we provide in connection with these Terms or any Services. You agree that we may provide Communications to you by posting them on the Site or sending them to the email you provided when using the Services, if any. You should keep copies of our Communications by printing a paper copy or saving an electronic one. You may also contact us with questions, complaints, or claims related to the Services at support@music.bet. <br />
            Remedies. Any rights or remedies provided to the Company in these Terms are in addition to and do not replace any other rights or remedies provided by law or in equity. The Company's failure or delay in exercising or enforcing its rights, powers, or privileges under these Terms does not constitute a waiver of those rights.
          </p>

        </Col>
      </Row>
    </div>
  );
};

export { Terms };
