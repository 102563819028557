import React, { useState } from "react";
import { ProfileLayout } from "../../../components/ProfileLayout/ProfileLayout";
import styles from './bonus.module.scss'
import { Button, Input } from "antd";
import { profileAPI } from "../../../api/api";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { setUserData } from "../../../store/playerSlice";
import { NotificationT } from "../../../components/ToastifyNot/NotificationToastify";
import { toast } from "react-toastify";

const Bonus = () => {

 const [bonusCode, setBonus] = useState(null);

	const dispatch = useAppDispatch();


 const usePromo = () => {
  profileAPI
   .usePromo({code: bonusCode})
   .then((res) => {
    profileAPI
				.getProfile()
				.then((res) => {
					dispatch(
						setUserData({
							first_name: res?.first_name,
							last_name: res?.last_name,
							nickname: res?.nickname,
							email: res.email,
							gender: res.gender,
							phone: res.phone,
							id: res.id,
							balance: res.balance,
							avatar: res?.avatar,
							first_voted: res?.data?.first_voted,
							income: res?.transaction_sums?.income,
							expense: res?.transaction_sums?.expense,
							transactions: res?.videobet_transactions,
							stocks: res?.stocks
						})
					)
				})
    .then(() => {
     toast.success("You successfully used your voucher")
    })
    .catch((err) => {
     toast.error("Something wrong");
    })
		}
 )}

 return (
  <ProfileLayout>
      {/* <NotificationT /> */}

   <div className={styles.buy_tokens_header}>

    <h2 className={styles.buy_tokens_header__text}>BONUS - FREE TOKENS</h2>
   </div>

  <div className={styles.bonus_filling}>
   <p className={styles.bonus_filling_header}>How to get free tokens?</p>
   <p>1.  Subscribe to our Instagram account: @Music_bet</p>
   <p>2. Send us a message on Instagram with the text: “MONETIZE MY SUBSCRIBERS”</p>
   <p>3. Copy the Promo code into this field that you will receive in a reply message on Instagram</p>
   <p>4. You will receive free tokens to your Music.bet wallet balance. </p>
   <h4 style={{marginBottom: 10}}>Token exchange rate: 1000 subscribers = 1 tkn</h4>
  </div>
  <Input onChange={(event) =>setBonus(event.target.value)} placeholder="Enter Promo code from message"/>
  <Button onClick={usePromo} className={styles.button}>Get free tokens</Button>
  </ProfileLayout>
 )
}

export default Bonus;
