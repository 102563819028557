import React, { useEffect, useState } from "react";
import { ProfileLayout } from "../../../components/ProfileLayout/ProfileLayout";
import { Table, TableProps } from "antd";
import styled from './BattleHistory.module.scss'
import { ReactComponent as Swap } from '../../../assets/musicbet/swap_vert.svg'
import mockImg from '../../../assets/mock/Battle_mokup 2.png'
import { profileAPI } from "../../../api/api";
interface DataType {
  id: number;
  battle: any;
  bets: {
    bet: number;
    lose: number;
  };
  details: {
    bet: number;
    battle_id: number;
    end: string;
    win: number;
  };
}

// const mock: DataType[] = [
//   {
//     id: 1,
//     battle: mockImg,
//     bets: { bet: 100, lose: 120 },
//     details: { bet: 19, battle_id: 10, end: "11.11.11", win: 100 }
//   }
// ];




const BattleHistory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(4); // Хранение текущего размера страницы

  const [data, setData] = useState<any>();

  useEffect(() => {
    profileAPI
      .getBids()
      .then((res) => {
        setData(res.data);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  const outputData = data?.map(item => ({
    id: item.id,
    battle: item.data.cover,
    bets: {
      bet: Number(item.bids_amount), // Преобразуем строку в число
      lose: Number(item.bids_amount) + 50 // Пример, можно подставить ваше значение
    },
    details: {
      bet: Number(item.current_user_bids_amount[item.track1_id]) || 0, // Ставка на track1
      battle_id: item.id,
      end: new Date(item.ended_at).toLocaleDateString(), // Преобразуем в нужный формат
      win: Number(item.bids_amount) + 100 // Пример, можно подставить ваше значение
    }
  }));

  const columns: TableProps<DataType>['columns'] = [
    {
      sortIcon: ({ sortOrder }) => <Swap className={styled.swap} order={sortOrder} />,
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <div className={record.id % 2 === 0 ? styled.evenRow : styled.oddRow}>
          <p>{text}</p>
        </div>
      ),
      sorter: (a, b) => b?.id - a?.id,
      width: 50
    },
    {
      title: 'Battle',
      dataIndex: 'battle',
      key: 'battle',
      render: (battle) =>
        <div className={styled.image_wrapp}>
          <img className={styled.image} src={battle} alt="cover" />
        </div>
    },
    {
      title: 'Bets',
      dataIndex: 'bets',
      key: 'bets',
      render: (bets) => (
        <div>
          <p>Bet: {bets.bet}</p>
          <p>Lose: {bets.lose}</p>
        </div>
      )
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (details) => (
        <div>
          <p>{details.bet}</p>
          <p>Battle #{details.battle_id}</p>
          <p>End: {details.end}</p>
          <p>Win: {details.win}</p>
        </div>
      )
    },
  ];


  return (
    <ProfileLayout>
     <Table
        dataSource={isLoading ? [] : outputData}
        className={styled.table}
        columns={columns}
        pagination={{
          pageSize, // Используем размер страницы из состояния
          showSizeChanger: true, // Включаем изменение размера страницы
          position: ['bottomCenter'],
          pageSizeOptions: ['4', '8', '16'],
          onShowSizeChange: (current, size) => setPageSize(size), // Обработчик изменения размера страницы
        }}
      />
    </ProfileLayout>
  );
}

export default BattleHistory;
